//TODO: Review this file in feature when DS changes happens
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Typography } from '@sbf/fairplay-nike';
import { nike } from '@sbf/fairplay-tokens';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';
import { variant } from '@/helpers/styles';
import { POSITIONS } from '@/services/home/home.types';

type SpacerProps = {
  title?: string;
  height?: number;
  position: POSITIONS;
  renderAs?: string;
  variant?: string;
  size?: string;
  color?: string | null;
  marginBottom?: number;
  marginTop?: number;
  width?: number | null;
  weight?: string | null;
  journal?: boolean;
};

const SpacerContainer = styled.div<SpacerProps>`
  ${({
    height,
    position,
    marginBottom,
    marginTop,
    width,
    journal,
    theme,
  }) => css`
    display: flex;
    justify-content: ${variant(position, {
      0: 'center',
      1: 'flex-end',
      2: 'flex-start',
    })};
    width: 100%;
    padding-left: ${journal ? theme.spaces[300] : 0};
    box-sizing: border-box;

    > p {
      ${media.greaterThan('desktop')} {
        width: ${width}%;
      }
      width: 100%;
      text-align: ${variant(position, {
        0: 'center',
        1: 'end',
        2: 'start',
      })};
    }

    justify-content: ${variant(position, {
      0: 'center',
      1: 'flex-end',
      2: 'flex-start',
    })};
    margin-bottom: ${marginBottom}rem;
    margin-top: ${marginTop}rem;
    align-items: center;
    height: ${height}rem;

    content-visibility: auto;
    contain-intrinsic-size: 6rem 4rem;
  `}
`;

const Spacer = ({
  title,
  height,
  position,
  color = nike.colors.primary,
  renderAs = 'span',
  variant = 'heading',
  size = 'h1',
  marginBottom = 0,
  marginTop = 0,
  width,
  weight = 'bold',
  journal = false,
}: SpacerProps) => {
  return (
    <SpacerContainer
      data-testid="spacer"
      height={height}
      marginBottom={marginBottom}
      marginTop={marginTop}
      position={position}
      width={width}
      journal={journal}
    >
      <Typography
        variant={variant}
        size={size}
        weight={weight}
        as={renderAs}
        color={color}
      >
        {title}
      </Typography>
    </SpacerContainer>
  );
};

export default Spacer;
